@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-image: url("../images/white.png");
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: right -200px;
}

html.dark body {
    background-color: black;
    background-image: url("../images/dark.png");
} 

.status-text {
    transform: scaleY(1.5); /* Adjust the value as needed */
}

.table {
width: 1000px;
text-align: center;
align-items: center;
}

tbody tr:hover {background-color: rgb(214, 232, 240);}
html.dark tbody tr:hover {background-color: rgb(143, 177, 192);
color: aliceblue;
}

/* Password buttons */
.input-group-password {
    position: relative;
    display: flex;
    align-items: center;
}

.input-group-password button {
    position: absolute;
    /*right: <appropriate distance>; *//* Adjust this based on your layout */
    /*height: <match input height>; *//* Make button height same as input field */
    border: none;
    background: none;
    cursor: pointer;
}

/* You might need to add individual right positioning for each button */
#fetch_password {
    right: 100px; /* Example value, adjust as needed */
}

#reveal_password {
    right: 60px; /* Example value, adjust as needed */
}

#copy_password {
    right: 20px; /* Example value, adjust as needed */
}
